import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/hero/Hero';
import Textblock from '../components/textblock/Textblock';
import Fullblock from '../components/fullblock/Fullblock';
import ContactForm from '../components/contactform/ContactForm';
import IconGallery from '../components/icongallery/IconGallery';

export default () => (
  <Layout>
    <Helmet
      title="NathanGriffen // eternityDigital :: Digital Designer // Front End Web Developer"
      meta={[
        {
          name: 'description',
          content:
            'Self taught digital designer / front end web developer looking to improve my skills and abilities. I am keen to learn and challenge myself by working on dynamic and exciting projects using the latest technologies as part of a larger team of like minded individuals.',
        },
        {
          name: 'keywords',
          content: 'web designer, digital design, front end web developer',
        },
        {
          name: 'google-site-verification',
          content: 'R-gG09aOlKR2njWzpcQd_0fKQJKVKxJp74x6vlqICAE',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <Hero
      heading="I'm a"
      taglineText={['Digital Designer.', 'FrontEnd Developer.', 'TableTop Gamer.']}
    />
    <main>
      <Textblock textblockTitle="Hi There and Welcome!">
        <p>
          <span>I&apos;m Nathan </span>
- digital designer / front end web developer from East Anglia
          always looking for ways to improve my skills and abilities. I am keen to learn and
          challenge myself by working on dynamic and exciting projects using the latest technologies
          as part of a larger team of like minded individuals. I also enjoy getting around the table
          with a group of friends and playing tabletop games, including recently running a local
          dungeons and dragons campaign.
        </p>
        <h4>
          If you&apos;d like to know more please visit my
          {' '}
          <Link to="/about">About Me</Link>
          {' '}
page.
        </h4>
        <p>
          You can also find me on
          {' '}
          <a href="http://linkedin.com/in/nathangriffen/">LinkedIn</a>
,
          {' '}
          <a href="http://twitter.com/Chibz0r">Twitter</a>
,
          {' '}
          <a href="https://www.instagram.com/chibz0r/">Instagram</a>
          {' '}
and
          {' '}
          <a href="https://github.com/chibz0r">GitHub</a>
        </p>
      </Textblock>
      <Fullblock>
        <h2>Skills and Software</h2>
        <IconGallery textBlock="Adobe CC Suite - Photoshop, Illustrator, Indesign, MacOS, HTML5, CSS, JS, SaSS, Terminal, GitHub, npm, gulp.js, Slack" />
      </Fullblock>
    </main>
    <ContactForm formTitle="Let's talk!" />
  </Layout>
);
