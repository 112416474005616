import React from 'react';

import './IconGallery.scss';
import CSS3 from '../../images/icons/css3.svg';
import Github from '../../images/icons/github.svg';
import HTML from '../../images/icons/html5.svg';
import JS from '../../images/icons/js.svg';
import npm from '../../images/icons/npm.svg';
import slack from '../../images/icons/slack.svg';

const icons = [
  { name: 'HTML', src: HTML },
  { name: 'CSS3', src: CSS3 },
  { name: 'JS', src: JS },
  { name: 'Github', src: Github },
  { name: 'npm', src: npm },
  { name: 'slack', src: slack },
];

const IconGallery = ({ textBlock }) => (
  <div className="IconGallery">
    {icons.map(icon => (
      <img key={icon.name} src={icon.src} alt={icon.name} />
    ))}
    <p>
      <em>{textBlock}</em>
    </p>
  </div>
);

export default IconGallery;
